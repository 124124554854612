const fieldsTypes = Object.freeze({
        SINGLE_LINE_TEXT: 0,
        MULTI_LINE_TEXT: 1,
        NUMBER: 2,
        AMOUNT: 3,
        DATE: 4,
        LINK: 5,
        LIST: 6
    })
export default fieldsTypes
